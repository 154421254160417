
import { Component, Vue, PropSync } from 'vue-property-decorator'
import { AMSAccountAPI, UserListsAPI } from '@/api'
import { UserList } from '@/global-types'
import QrcodeVue from 'qrcode.vue'
import { BRow } from 'bootstrap-vue'

interface userListSummary {
  id: string,
  count: number,
  label: string,
}

@Component({
  components: {
    QrcodeVue,
  },
})
export default class UserListsModal extends Vue {
  @PropSync('showModal', { type: Boolean }) syncedShowModal!: boolean

  fields = [
    'List Name',
    'Items',
    'Manage',
  ]

  amsLoggedIn = false
  showModal = false
  showNameEntry = false
  nameTask = 'new_list'
  nameInputText = ''

  listSummary: userListSummary[] = []
  userLists: UserList[] = []

  showDeleteEntry = false
  deleteItem:userListSummary | null = null

  syncedShowSharing = false
  sharedItem:userListSummary | null = null
  // fullSharedURL = ''
  fullSharedURL = ['', '']
  shortSharedURL = ['', '']
  dismissCountDown = 0

  showImporter = false
  importerUrl = ''

  mounted() {
    UserListsAPI.setupEventListener(this.userListsStorageUpdated)
    this.userListsStorageUpdated()
    this.checkIfAMSLoggedIn()
  }

  userListsStorageUpdated() {
    this.userLists = UserListsAPI.pullFromLocalStorage()
    const newListSummary: userListSummary[] = []
    for (const i in this.userLists) {
      const list = this.userLists[i]
      newListSummary.push({
        label: list.label,
        count: list.mrNumbers.length,
        id: list.id!,
      })
    }
    // Replace the old listSummary
    this.listSummary.splice(0, this.listSummary.length, ...newListSummary)
  }

  startCreateList() {
    this.nameTask = 'new_list'
    this.nameInputText = ''
    this.showNameEntry = true
  }

  startRenameList(id:string, label:string) {
    this.nameTask = id
    this.nameInputText = label
    this.showNameEntry = true
  }

  startDeleteList(list:userListSummary) {
    this.deleteItem = list
    this.showDeleteEntry = true
  }

  createList(label: string) {
    const newList: UserList = {
      label: label,
      mrNumbers: [],
    }
    UserListsAPI.createList(newList)
    this.showNameEntry = false
  }

  renameList(id: string, newName: string) {
    UserListsAPI.updateList(id, newName)
    this.showNameEntry = false
  }

  shareList(list:userListSummary) {
    this.sharedItem = list
    const props = this.$router.resolve({
      name: 'MRLists',
      params: {
        listId: this.sharedItem?.id,
      },
    })
    // Some places, like the University of Michigan proxy, have a window.location.orgin that is specific to their use case. Provide a generic link too.
    this.fullSharedURL[0] = `${window.location.origin}${props.href}`
    this.shortSharedURL[0] = window.location.origin.split('.').slice(-2).join('.') // Get the last two parts of the domain, like umich.edu or ams.org
    if (window.location.origin !== 'https://mathscinet.ams.org') {
      if (window.location.origin.includes('localhost') || window.location.origin.includes('mrweb.ams.org')) {
        // These internal urls sometimes have "beta" in them, or other formatting issues. A hardcoded version is more likely to succeed.
        this.shortSharedURL[0] = window.location.origin.replace('http://', '').replace('https://', '').replace('www', '') // Need a bigger name, since it's likely mrweb.ams.org
        this.shortSharedURL[1] = 'Public AMS.org'
        this.fullSharedURL[1] = `https://mathscinet.ams.org/mathscinet/mr-lists/${this.sharedItem?.id}`
      } else {
        // They're already in production, using the production URLs. This will be the safest options
        this.fullSharedURL[1] = `https://mathscinet.ams.org${props.href}`
        this.shortSharedURL[1] = 'AMS.org'
      }
    }
    this.syncedShowSharing = true
  }

  async importList() {
    const splitUrl = this.importerUrl.split('/')
    const listId = splitUrl[splitUrl.length - 1]
    const originalList = await UserListsAPI.getSingleList(listId) // Don't want the cache to store with the original version
    const newList:UserList = {
      mrNumbers: originalList.mrNumbers,
      label: originalList.label,
    }
    UserListsAPI.createList(newList)
    this.showImporter = false
  }

  deleteList(id: string) {
    UserListsAPI.deleteList(id)
    this.showDeleteEntry = false
  }

  async checkIfAMSLoggedIn() {
    this.amsLoggedIn = await AMSAccountAPI.isLoggedIn()

    if (!this.amsLoggedIn) {
      // Could be a different user
      UserListsAPI.clearLocalStorage()
    } else {
      // Update the cached lists
      UserListsAPI.getLists()
    }
  }

  onClickLoginToEdit() {
    const url = window.location.href
    AMSAccountAPI.redirectToAMSLogin(url)
  }

  copyURL(index: number) {
    const copyText = document.getElementById(`sharedUrlText_${index}`) as HTMLInputElement
    if (copyText) {
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */
      navigator.clipboard.writeText(copyText.value)
      copyText.setSelectionRange(0, 0) /* Clear the highlighting from the text */

      // Notify that the text was copied
      this.dismissCountDown = 3
    }
  }

  countDownChanged(dismissCountDown: number): void {
    this.dismissCountDown = dismissCountDown
  }
}
